<template>
  <div>
    <PageDynamicBreadcrumb custom-current-page="Overview" />
    <PageHeader title="Ads Inventory Library Overview">
      <template v-slot:actions>
        <q-btn
          label="Add New"
          to="/ads/inventory-library/selector-tool"
          color="primary"
        />
      </template>
    </PageHeader>

    <DataTable
      :table-key="tableKey"
      row-key="product_id"
      title="Active Vehicles"
      :columns="columns"
      :rows="vehicles"
      :loading="loading"
      :pagination="pagination"
      :downloadable="false"
      :refreshable="true"
      :customizable="false"
      :filterable="true"
      :server-side="true"
      :searchable="true"
      :disable-state="false"
      :dimension-filter-options-by-column="dimensionFilterOptionsByColumn"
      @update-rows="val => getVehicles(val)"
    >
      <template v-slot:actions="row">
        <ActionsDropdownButton
          :actions="[
            {
              action: () => promptDeleteInventoryLibraryVehicle(row),
              label: 'Delete'
            }
          ]"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete";
import { Dialog, QImg } from "quasar";
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import axios from "axios";
import DataTable from "@/components/DataTable";
import DataTableMixin from "@/mixins/DataTableMixin";
import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton";
import moment from "moment";

export default {
  name: "VehiclesInventoryLibraryOverview",
  mixins: [DataTableMixin],
  components: {
    ActionsDropdownButton,
    DataTable,
    PageDynamicBreadcrumb,
    PageHeader
  },
  data() {
    return {
      loading: true,
      tableKey: "vehiclesInventoryLibraryOverview",
      vehicles: [],
      pendingGetVehiclesRequests: [],
      pagination: {
        sortBy: "time_created",
        descending: true,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 1000
      },
      dimensionFilterOptionsByColumn: {},
      columns: [
        {
          name: "image_url",
          label: "Image",
          hideField: true,
          isComponentField: true,
          field: row => ({
            component: QImg,
            componentProperties: {
              src: row.image_url,
              fit: "contain",
              width: "70px",
              style: "width: 70px; height: 50px;"
            },
            componentBody: ""
          }),
          sortable: false,
          type: "dimension",
          filterable: false
        },
        {
          name: "product_id",
          label: "Product ID",
          field: "product_id",
          type: "dimension",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          filterable: false
        },
        {
          name: "selection_method",
          label: "Selection Method",
          field: "selection_method",
          format: val => this.capitalizeText(val),
          type: "dimension",
          sortable: false
        },
        {
          name: "vin",
          label: "VIN",
          field: "vin",
          type: "dimension",
          sortable: true,
          filterable: false
        },
        {
          name: "year",
          label: "Year",
          field: "year",
          type: "metric",
          metricType: "year",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b)
        },
        {
          name: "make",
          label: "Make",
          field: "make",
          type: "dimension",
          sortable: true
        },
        {
          name: "model",
          label: "Model",
          field: "model",
          type: "dimension",
          sortable: true
        },
        {
          name: "price",
          label: "Price",
          field: "price",
          format: val => "$" + Intl.NumberFormat().format(val),
          type: "metric",
          metricType: "number",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b)
        },
        {
          name: "mileage",
          label: "Mileage",
          field: "mileage",
          format: val => Intl.NumberFormat().format(val),
          type: "metric",
          metricType: "number",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b)
        },
        {
          name: "dealer_city",
          label: "City",
          field: row => row.dealer.city,
          type: "dimension",
          sortable: true,
          filterable: false
        },
        {
          name: "dealer_state",
          label: "State",
          field: row => row.dealer.state,
          type: "dimension",
          sortable: true
        },
        {
          name: "time_created",
          label: "Time Added",
          field: row => moment.utc(row.time_created).local(),
          format: val => (!val ? "-" : moment(val).format("lll")),
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          type: "metric",
          metricType: "date",
          align: "left"
        },
        {
          name: "actions",
          label: "Actions",
          field: "",
          align: "right",
          sortable: false,
          filterable: false
        }
      ]
    };
  },
  beforeMount() {
    let urlParamDataTableState = this.loadDataTableStateFromURLParam(
      this.tableKey,
      this.columns
    );

    this.columns = urlParamDataTableState.columns;

    if (Object.keys(urlParamDataTableState.pagination).length > 0) {
      this.pagination = urlParamDataTableState.pagination;
    }
    if (urlParamDataTableState.searchFilter.length > 0) {
      this.searchFilter = urlParamDataTableState.searchFilter;
    }
  },
  mounted() {
    this.getVehicles();
  },
  methods: {
    getVehicles(dataTableState = null) {
      this.loading = true;

      // If we dont have a dataTableState (passed in events emitted by the datatable), then fallback to default initial request.
      if (!dataTableState) {
        dataTableState = {
          columns: this.columns,
          pagination: this.pagination,
          searchFilter: ""
        };
      }

      // Abort any existing requests
      if (this.pendingGetVehiclesRequests.length > 0) {
        this.pendingGetVehiclesRequests.forEach(controller => {
          controller.abort();
        });
        this.pendingGetVehiclesRequests = [];
      }
      let controller = new AbortController();
      let { signal } = controller;
      this.pendingGetVehiclesRequests.push(controller);

      // Configure current request pagination
      let paginationParams = {
        per_page: dataTableState.pagination.rowsPerPage,
        page: dataTableState.pagination.page
      };
      if (dataTableState.pagination.sortBy) {
        paginationParams.sort_by =
          dataTableState.pagination.sortBy +
          "|" +
          (dataTableState.pagination.descending ? "desc" : "asc");
      }

      // Configure current request filter params
      let filterParams = {};
      if (dataTableState.searchFilter.length > 2) {
        filterParams.keyword = dataTableState.searchFilter;
      }

      // Account for all column filters dynamically
      dataTableState.columns
        .filter(c => c.type === "dimension")
        .forEach(column => {
          if (column.activeFilterCount > 0) {
            filterParams[column.name] = column.filterValues.in.join(",");
          }
        });

      dataTableState.columns
        .filter(c => c.type === "metric")
        .forEach(column => {
          if (column.activeFilterCount > 0) {
            if (column.filterValues.gt.length > 0) {
              filterParams[column.name + "_min"] = +column.filterValues.gt + 1;
            }
            if (column.filterValues.lt.length > 0) {
              filterParams[column.name + "_max"] = +column.filterValues.lt - 1;
            }
          }
        });

      axios({
        url:
          "https://api-v2.jumpfeed.com/internal/ads/vehicles-inventory-library",
        method: "GET",
        signal: signal,
        params: {
          ...paginationParams,
          ...filterParams,
          images: 1,
          raw_data: 1,
          dealer_data: 1,
          name_data: 1,
          facets_metadata: 1
        }
      })
        .then(response => {
          this.vehicles = response.data.data.vehicles;

          let totalVehicles = parseInt(response.data.data.totals.vehicles);
          this.pagination.rowsNumber =
            totalVehicles > 10000 ? 10000 : totalVehicles;
          this.pagination.totalRows = totalVehicles;

          this.dimensionFilterOptionsByColumn = {};
          let facetsByColumn = response.data.metadata.facets;
          Object.keys(facetsByColumn).forEach(column => {
            this.dimensionFilterOptionsByColumn[column] = facetsByColumn[column]
              .filter(f => f.key.toString().length > 0)
              .map(facet => ({
                label: facet.key,
                value: facet.key
              }));
          });

          this.loading = false;
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            return;
          }
          this.loading = false;
        });
    },
    promptDeleteInventoryLibraryVehicle(vehicle) {
      Dialog.create({
        component: ConfirmationModalDelete,
        componentProps: {
          descriptor:
            vehicle.product_id +
            " (" +
            vehicle.year +
            " " +
            vehicle.make +
            " " +
            vehicle.model +
            ")"
        }
      }).onOk(dialog => {
        dialog.submitting = true;
        axios
          .post(
            "https://api-v2.jumpfeed.com/internal/ads/vehicles-inventory-library/remove",
            {
              product_id: vehicle.product_id
            }
          )
          .then(() => {
            this.triggerActionOutcomeAlert("delete", true);
            dialog.hide();
          })
          .catch(err => {
            console.log(err);
            this.triggerActionOutcomeAlert("delete", false);
          })
          .finally(() => {
            dialog.submitting = false;
            this.getVehicles();
          });
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
